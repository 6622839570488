<script lang="ts" setup>
import type { ContentTypeArticle } from '~~/types/storyblok/contentTypes'
import type { MediaImage } from '~~/types/storyblok/media'

const props = defineProps<{
  title: string
  path: string
  blok: ContentTypeArticle
}>()

const heroImage = computed(() => {
  return {
    _uid: 'hero',
    component: 'MediaImage',
    asset: props?.blok?.hero,
  } as MediaImage
})
</script>

<template lang="pug">
transition(
  name="fade"
  appear
)
  article(
    v-editable="blok"
    class="blok-article bg-grey-200 relative transition-all ease-in-out duration-500 shadow-lg hover:-translate-y-6 hover:shadow-2xl"
  )
    NuxtLink(:to="`/${path}`")
      MediaImage(
        :blok="heroImage"
        :with-overlay="true"
        :object-cover="true"
        :height="640"
        aspect="insight-card"
      )
    div(
      class="flex flex-col justify-between absolute inset-0 w-full h-full p-24 z-10 items-start pointer-events-none"
    )
      NuxtLink(:to="`/${path}`")
        h3(class="font-sans text-xl font-bold text-grey-100") {{ props.title }}
      NuxtLink(:to="`/${path}`" class="button button--highlight")
        span Read
        MaterialSymbol arrow_right_alt
</template>
